<template>
    <div>
        <terms-component />
    </div>
</template>

<script>
import TermsComponent from '@/components/admin/terms/termsList'
export default {
    name: "AdminTerms",
    components: { TermsComponent },
    data: () => ({

    })
};
</script>
